<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('EVENTS')}}</h1>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t('ID') }}</div>
          <div class="cell">{{ $t('NAME') }}</div>
          <div class="cell">{{ $t('EVENT_VALUES.START_DATE') }}</div>
          <div class="cell">{{ $t('EVENT_VALUES.END_DATE') }}</div>
          <div class="cell">{{ $t('EVENT_VALUES.STATE') }}</div>
          <div class="cell">{{ $t('EVENT_VALUES.TYPE') }}</div>
          <div class="cell">{{ $t('EVENT_VALUES.MODIFIER_TYPE') }}</div>
          <div class="cell"><a @click="$router.push({ name: 'EventsAdd' })" class="success">{{ $t('ADD') }}</a></div>
        </div>
        <div class="table-row" v-for="(event, index) in eventsArr" v-bind:key="event.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell cell-small">{{ event.id }}</div>
          <div class="cell">{{ event.name }}</div>
          <div class="cell">{{ moment(event.start_date).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell">{{ moment(event.end_date).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell">
            <label :class="Date.now() > event.end_date ? 'ended': (Date.now() > event.start_date ? 'active' : 'scheduled')">
              {{ (Date.now() > event.end_date ? 'ENDED': (Date.now() > event.start_date ? 'ACTIVE' : 'SCHEDULED')) }}
            </label>
          </div>
          <div class="cell">{{ $t("EVENT_VALUES." + (eventConstants.EVENT_TYPES.find(x => x.value === event.type) || {}).text) }}</div>
          <div class="cell">
            <template v-if="event.type === 1">
              {{ $t("EVENT_VALUES." + (eventConstants.GAME_MODE_MODIFIER_TYPES.find(x => x.value === event.modifier_type) || {}).text)}}
            </template>
          </div>
          <div class="cell">
            <a @click="$router.push({ name: 'EventsAdd', query: { id: event.id } })" class="info"><i class="fas fa-edit"></i></a>
            <button v-if="Date.now() > event.end_date || Date.now() < event.start_date" @click="() => deleteEvent(event.id)" class="error" :disabled="Date.now() > event.start_date && event.end_date > Date.now()"><i class="fas fa-times"></i></button>
          </div>
        </div>
      </div>
    </div>
        <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType" :changes="changes"/>

  </div>
</template>

<script>
import { watch, ref, onBeforeMount, onUpdated, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import moment from "moment";

import eventConstants from "@/constants/eventConstants";
import dispatchMap from "@/constants/dispatchMap";
import { io } from "socket.io-client";
import ConfirmPopup from '../../components/common/ConfirmPopup.vue'
export default {
  name: "EventChallengeList",
  components: {
    ConfirmPopup
  },
  setup() {
    const store = useStore();
    const eventsArr = ref([])
    const usedPages = ["events"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const popupOpen = ref(false);
    const popupText = ref("This page is edited by another user. Please refresh before making changes.");
    const popupType = ref("refresh");
    const changes = ref([]);
    onBeforeMount(() => usedPages.forEach(page => {
      dispatchMap[page].forEach(dispatchStr => store.dispatch(dispatchStr));
    }));
    watch(() => store.getters['events/getEvents'], value => {
      eventsArr.value = [ ...value ].filter(x => x.type === 1).sort((a,b) => b.start_date - a.start_date)
    }, { immediate: true })
    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    const deleteEvent = (id) => {
      store.dispatch('events/deleteEvent', { id })
    }
    socket.on('added', (data) => {
      if (data.page === "Event Challenge" && data.data){
        popupOpen.value = true;
        changes.value.push("Event " + data.data + " is added.")
      }
    })
    return {
      eventsArr,
      eventConstants,
      deleteEvent,
      moment,
      popupOpen,
      popupText,
      popupType,
      changes
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr 2fr 1fr 2fr 2fr 2fr;
  text-align: start;
  align-items: center;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

button, a {
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 400;
  display: block;
  text-decoration: none;
  color: black;
  width: auto;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 10px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.cell.cell-small {
  font-size: 0.8rem;
}

label {
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}

label.ended {
  background-color: rgba(255, 0, 0, 0.15);
}

label.active {
  background-color: rgba(0, 255, 0, 0.15);
}

label.scheduled {
  background-color: rgba(0, 0, 255, 0.15);
}
</style>